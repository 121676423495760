import "./HomePage.scss";
import * as React from 'react';
import { useContext, useEffect, useState, useCallback, FC } from "react";
import {
    IonContent,
    IonItemDivider,
    IonPage,
} from "@ionic/react";
import * as Sentry from "@sentry/react";
import SwiperCore, { FreeMode, Navigation } from 'swiper';
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import { useHistory, useLocation } from "react-router-dom";
import { ApplicationContext } from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import FooterComponent from "../../components/Footer/FooterComponent";
import { CategoryHeader } from '../../components/ContentTiles/CategoryHeader';
import {
    MINDFULBREAK_MODAL, LIVECHAT_MODAL, getTranslatedGreeting
} from '../../utils/utils';
import { ContentCarousel } from "../../components/ContentTiles/ContentCarousel";
import { VideoCategoryResponse, ContentItem, GetCompanyBenefitsInfoResponse, ApiAsset, EAPDescriptionType } from "../../utils/ApiTypes";
import { CheckinGraph, CheckinState } from "../../components/CheckinGraph/CheckinGraph";
import AnalyticsService from "../../misc/AnalyticsService";
import { LoginModalComponent } from "../../components/LoginModalComponent/LoginModalComponent";
import { ActivityCalendar } from '../../components/ActivityCalendar/ActivityCalendar';
import { DailyPractice } from "../../components/DailyPractice/DailyPractice";
import { AssessmentPopover } from "../../components/AssessmentPopover/AssessmentPopover";
import { TalkToSomeone } from "../../components/TalkToSomeone/TalkToSomeone";
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Feature, FeatureShowContents } from "../../components/Feature/Feature";
import { ResponsiveNavigationMenu } from "../../components/Navigation/ResponsiveNavigationMenu";
import { WorklifeComponent } from "../../components/Worklife/WorklifeComponent";
import { ProactiveClinicialCareComponent } from "../../components/ProactiveClinicialCare/ProactiveClinicalCareComponent";
import { TalkItOutCard } from "../../components/TalkItOutCard/TalkItOutCard";
import { CheckinCardLong } from "../../components/CheckinCardLong/CheckinCardLong";


export const HomePage: React.FC = () => {
    const { t } = useTranslation();
    const [recommendedPlaceholders, setRecommendedPlaceholders] = useState<ContentItem[]>([]);
    const [myListPlaceholders, setMyListPlaceholders] = useState<ContentItem[]>([]);
    const [recommendations, setRecommendations] = useState<ContentItem[]>([]);
    const [topics, setTopics] = useState<ApiAsset[]>([]);
    const [benefitsInfo, setBenefitsInfo] = useState<GetCompanyBenefitsInfoResponse | null>(null);
    const [collections, setCategories] = useState<VideoCategoryResponse[]>([]);
    const [liveStreams, setLiveStreams] = useState<ContentItem[]>([]);
    const [checkinState, setCheckinState] = useState<CheckinState>('not-checkedin');
    const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
    const [isContentLoading, setIsContentLoading] = useState<boolean>(true);
    const [triggerChatWithUsButton, setTriggerChatWithUsButton] = useState<boolean>(false);
    const {
        isMobileWidth,
        currentUser,
        handleGeneralError,
        appResumeCounter,
        language,
        hasCompletedAssessment,
        openMindfulBreakModal,
    } = useContext(ApplicationContext);
    const [checkinCounter, setCheckinCounter] = useState<number>(0);
    const [longCheckinCounter, setLongCheckinCounter] = useState<number>(0);
    const [checkinRating, setCheckinRating] = useState<number | undefined>();
    const isComponentVisible = useIsComponentVisible();
    const history = useHistory();
    const location = useLocation();
    //const { t } = useTranslation();
    SwiperCore.use([Navigation, FreeMode]);

    const savedListResults = useQuery(
        ["fetchSavedList"],
        () => JourneyApiClient.getSavedVideos(),
        { enabled: isComponentVisible && !!currentUser }
    );

    const queryClient = useQueryClient();

    function updateSavedListResults() {
        queryClient.invalidateQueries(["fetchSavedList"]);
        queryClient.invalidateQueries(["fetchTopicList"]);
    }

    function getModalQueryParams() {
        let modal = new URLSearchParams(location.search).get("modal");

        if (modal === MINDFULBREAK_MODAL) {
            openMindfulBreakModal();
        } else if (modal === LIVECHAT_MODAL) {
            setTriggerChatWithUsButton(true);
        }
    }

    useEffect(() => {
        if (!isComponentVisible || isContentLoading) return;
        getModalQueryParams();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible, isContentLoading]);

    useEffect(() => {
        if (currentUser) {
            let redirectUrl = JourneyApiClient.getRedirectUrl();
            if (redirectUrl && redirectUrl !== '/') {
                JourneyApiClient.clearRedirectUrl();
                history.replace(redirectUrl);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible]);

    useEffect(() => {
        if (!isComponentVisible) return;
        setIsContentLoading(true);
        const contentRequests = [
            getHomeContent(),
            getLiveStreams(),
            getResources(),
            getRecommendations(),
        ];
        Promise.all(contentRequests).then(() => setIsContentLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible, appResumeCounter, language]);

    const anchorLoaded = useCallback(async () => {
        if (window.location.hash) {
            const fragment = window.location.hash;
            window.location.hash = '';
            await new Promise(r => setTimeout(r, 300));
            window.location.hash = fragment;
        }
    }, []);


    useEffect(() => {
        (async function () {
            if (!hasCompletedAssessment) return;
            //Do not Remove, Timeout is needed since hasComletedAssessment changes faster(Race condition) than saving data on DB
            await new Promise(r => setTimeout(r, 500));
            await getRecommendations();
        })();
    }, [hasCompletedAssessment]);

    if (savedListResults.status === "error") {
        Sentry.captureException(savedListResults.error);
    }

    const getHomeContent = async () => {
        try {
            const categories = await JourneyApiClient.getHomeContent({ excludeLiveCategory: true })
            setCategories(categories);
            if (!myListPlaceholders.length) {
                setMyListPlaceholders(selectRandomVideos(categories));
            }
            if (!recommendedPlaceholders.length) {
                setRecommendedPlaceholders(selectRandomVideos(categories));
            }

        } catch (ex) {
            handleGeneralError("Could not get home content", ex);
        }
    }

    const getLiveStreams = () => {
        return JourneyApiClient.getLiveStreamContent()
            .then(setLiveStreams)
            .catch(error => handleGeneralError("Could not get home content", error))
    }

    const getResources = async () => {
        const benefitsInfo = await JourneyApiClient.getCompanyBenefitsInfo();
        setBenefitsInfo(benefitsInfo);
    }

    const getTopics = useQuery(
        ["fetchTopicList"],
        () => JourneyApiClient.getAssets("topic"),
        { 
            enabled: isComponentVisible && !!currentUser,
            retry: false,
            onSuccess: (data) => {
                setTopics(data);
            },
            onError: (error) => {
                Sentry.captureException(error);
            }
        }
    );

    const getRecommendations = async () => {
        const recommendations = await JourneyApiClient.getIonicRecommendations();
        setRecommendations(recommendations);
    }

    const openLoginModal = async () => {
        if (currentUser) return;
        setIsLoginModalOpen(true);
        await AnalyticsService.trackUserAction("view_login_modal", location.pathname);
    }

    const greeting = getTranslatedGreeting(currentUser, t);

    const liveStreamSection = (
        <>
            {(liveStreams.length > 0) && (
                <a id={"liveclasses"} ref={anchorLoaded}>
                    <Feature feature='live-streams'>
                        <div className={"industry-targeting-row-wrapper"}>
                            <div className="video-listing-row-container">
                                <div className="video-listing-row">
                                    <CategoryHeader
                                        name={t('Live Classes: Connect LIVE With Our Expert Teachers & Supportive Community')}
                                        description={null}
                                        onClick={openLoginModal}
                                    />
                                    <ContentCarousel
                                        onSaveChange={updateSavedListResults}
                                        onClick={() => {
                                            openLoginModal();
                                            AnalyticsService.trackUserAction("hp_livestream_video", location.pathname);
                                        }}
                                        items={liveStreams}
                                    />
                                </div>
                            </div>
                            <div className="home-page-line-divider-container">
                                <IonItemDivider className="home-page-line-divider" />
                            </div>
                        </div>
                    </Feature>
                </a>
            )}
        </>
    );


    const CheckinCardAndDailyPractice = (
        <>
            <CheckinGraph onCheckin={((state, feelingRating) => {
                setCheckinState(state);
                setCheckinCounter(checkinCounter + 1);
                setCheckinRating(feelingRating);
            })} />

            <DailyPractice state={checkinState === 'checkedin' || longCheckinCounter === checkinCounter ? 'autoplay' : checkinState === "checkedin-previously" ? 'load-video' : 'placeholder'} />
        </>
    );

    return (
        <IonPage
            className={`home-page ${(isLoginModalOpen) ? "blur-content" : ""}`}
            id="main-page">

            <ResponsiveNavigationMenu handleOpenLoginModal={openLoginModal} />

            <LoginModalComponent
                isComponentVisible={isComponentVisible}
                isModalOpen={isLoginModalOpen}
                closeModal={() => setIsLoginModalOpen(false)}
            />
            <Feature feature="proactive-clinical-care">
                <ProactiveClinicialCareComponent checkInStateValue={checkinState} checkinRating={checkinRating}/>
            </Feature>

            <IonContent className='main-content-container'>
                <div className="home-page-daily-checkin">

                    <Feature feature="talk-it-out-home-page">
                        
                        <div className="checkin-steps-container-with-talk-it-out">
                        {
                            ["checkedin", "checkedin-previously"].includes(checkinState) ?
                                <>
                                    {CheckinCardAndDailyPractice}
                                    <TalkItOutCard />
                                </>
                            :
                                <>
                                    <CheckinCardLong userName={currentUser?.firstName} onCheckin={((state, feelingRating) => {
                                        setCheckinState(state);
                                        setCheckinCounter(checkinCounter + 1);
                                        setCheckinRating(feelingRating);
                                        setLongCheckinCounter(longCheckinCounter + 1);
                                    })}/>
                                    <TalkItOutCard />
                                </>
                        }
                        </div>

                    </Feature>
                    <Feature feature="talk-it-out-home-page" showContents={FeatureShowContents.whenDisabled}>

                        {currentUser?.firstName && <h6 className="user-greeting-text h6-bold">{greeting}</h6>}
                        <div className="checkin-steps-container">
                            {CheckinCardAndDailyPractice}
                            <ActivityCalendar refreshCounter={checkinCounter} />
                        </div>

                    </Feature>

                    <div className={"home-page-talk-someone-container"}>
                        {currentUser && <Feature feature="has-eap">
                            <TalkToSomeone
                                className={"home-page-talk-someone"}
                                isChatWithUsButtonTriggered={triggerChatWithUsButton}
                                user={currentUser}
                                isMobileWidth={isMobileWidth}
                                showDescription={true}
                                showDescriptionInPopover={false}
                                isCondensed={false}
                                eapDescription={EAPDescriptionType.Default}
                                isComponentVisible={isComponentVisible} 
                                subtitle={null} />
                        </Feature>}
                    </div>
                    <Feature feature="work-life">
                        <WorklifeComponent/>
                    </Feature>
                </div>
                {
                    currentUser && benefitsInfo && (benefitsInfo.companyBenefits.filter(cb => cb.isOnHomePage).length >= 2) &&
                    <Feature feature="has-eap" showContents={FeatureShowContents.whenDisabled}>
                        <div className="video-listing-row-container">
                            <div className="video-listing-row resources">
                                <div className="benefits">

                                    {benefitsInfo.companyBenefits.filter(cb => cb.isOnHomePage).map((benefit, i) => {
                                        if (benefit.redirectUrl)
                                            return <a
                                                onClick={() => AnalyticsService.trackUserAction("hp_resource_click", location.pathname, {
                                                    name: benefit.name
                                                })}
                                                target='_blank' rel="noreferrer" key={i} href={benefit.redirectUrl ?? ''} className='logo'><img
                                                    src={benefit.imageUrl!} alt="" /></a>
                                        else
                                            return <span key={i} className="logo"><img src={benefit.imageUrl!} alt="Benefit" /></span>

                                    })}
                                </div>
                            </div>
                            <div className="home-page-line-divider-container first"><IonItemDivider className="home-page-line-divider" /></div>
                        </div>
                    </Feature>
                }
                {recommendations.length > 0 &&
                    <Feature feature='recommendations'>
                        <div className={"industry-targeting-row-wrapper"}>
                            <div className="video-listing-row-container">
                                <div className="video-listing-row">
                                    <CategoryHeader
                                        name={t("Recommended")}
                                        description={null}
                                        onClick={openLoginModal}
                                    >
                                        <AssessmentPopover />
                                    </CategoryHeader>
                                    <ContentCarousel onSaveChange={updateSavedListResults} onClick={() => {
                                        openLoginModal();
                                        AnalyticsService.trackUserAction("hp_recommended_video", location.pathname);
                                    }} items={recommendations} />
                                </div>
                                <div className="home-page-line-divider-container"><IonItemDivider className="home-page-line-divider" /></div>
                            </div>
                        </div>
                    </Feature>
                }

                {liveStreamSection}
                {
                    savedListResults.status === "success" && savedListResults.data.length > 0 &&
                    <div className={"industry-targeting-row-wrapper"}>
                        <div className="video-listing-row-container">
                            <div className="video-listing-row">
                                <CategoryHeader
                                    name={t("My List")}
                                    description={null}
                                    onClick={openLoginModal}
                                />
                                <ContentCarousel pageSource="my_list" onSaveChange={updateSavedListResults} onClick={() => {
                                    openLoginModal();
                                    AnalyticsService.trackUserAction("hp_mylist_item", location.pathname);
                                }} items={savedListResults.data} />
                            </div>
                            <div className="home-page-line-divider-container"><IonItemDivider className="home-page-line-divider" /></div>
                        </div>
                    </div>
                }
                
                {false && topics && topics.length > 0 && <div className={"industry-targeting-row-wrapper"}>
                    <div className="video-listing-row-container">
                        <div className="video-listing-row">
                            <CategoryHeader
                                name={t("Topics")}
                                description={null}
                                onClick={openLoginModal}
                            />
                            <ContentCarousel onSaveChange={updateSavedListResults} onClick={() => {
                                openLoginModal();
                                AnalyticsService.trackUserAction("hp_category_item", location.pathname);
                            }} items={topics} />
                        </div>
                        <div className="home-page-line-divider-container"><IonItemDivider className="home-page-line-divider" /></div>
                    </div>
                </div>}
                {
                    collections.map((category, index) => {
                        return (
                            <div className={"industry-targeting-row-wrapper"} key={index}>
                                <div className="video-listing-row-container">
                                    <div className="video-listing-row">
                                        <CategoryHeader
                                            name={category.name}
                                            description={category.shortDescription}
                                            path={`/category/${category.id}`}
                                            onClick={openLoginModal}
                                        />
                                        <ContentCarousel onSaveChange={updateSavedListResults} onClick={() => {
                                            openLoginModal();
                                            AnalyticsService.trackUserAction("hp_category_item", location.pathname);
                                        }} items={category.items} />
                                    </div>
                                    {index < collections.length - 1 && <div className="home-page-line-divider-container"><IonItemDivider className="home-page-line-divider" /></div>}
                                </div>
                            </div>
                        )
                    })
                }

                {!isContentLoading && <FooterComponent />}
            </IonContent>
        </IonPage>
    );

}

function selectRandomVideos(categories: VideoCategoryResponse[]): ContentItem[] {
    const items = categories
        .filter(category => !category.name.startsWith('Live Classes:') && !category.name.startsWith('Replays:'))
        .reduce((list, category) => list.concat(category.items), [] as ContentItem[]);
    const result: ContentItem[] = [];
    if (items.length === 0) return [];
    for (let i = 0; i < 4; i++) {
        result.push(items[Math.floor(Math.random() * items.length)]);
    }
    return result;
}
